<template>
  <BasicModal
    action-title="Save"
    :action="submitForm"
    title="Create webhook"
    :close-on-action="false"
    :disable-action-button="form.isLoading || !isFormValid"
  >
    <p class="mb-6">
      Automatically send Dispatch alerts to your app or service with webhooks.
      <a target="_blank" href="https://docs.esprezzo.io/webhooks/overview">Learn more.</a>
    </p>
    <FormSubmitErrors :formName="formName" />
    <FormInput :formName="formName" fieldName="name" ref="focusElement" />
    <FormInput :formName="formName" fieldName="defaultOutput" />
    <LoadingOverlay v-if="form.isLoading" />
  </BasicModal>
</template>

<script>

  import useForm from '@/composables/useForm'

  import FormInput from '@/components/forms/FormInput.vue'
  import LoadingOverlay from '@/components/utils/LoadingOverlay.vue'
  import FormSubmitErrors from '@/components/forms/FormSubmitErrors.vue'

  import BasicModal from '@/components/modals/_BasicModal.vue'

  export default {
    components: {
      FormInput,
      BasicModal,
      LoadingOverlay,
      FormSubmitErrors,
    },
    props: {
      onSuccess: {
        type: Function,
        required: true,
      },
      onError: {
        type: Function,
        required: true,
      },
    },
    setup() {

      // data
      const formName = 'createAccountWebhookForm'

      // composables
      const { form, isFormValid, focusElement } = useForm({ formName })

      return {
        form,
        formName,
        isFormValid,
        focusElement,
      }

    },
    methods: {
      close() {
        this.$store.dispatch('modals/CLOSE_MODAL')
      },
      submitForm() {
        return this.$store.dispatch('forms/SUBMIT_FORM', this.formName)
          .then((response) => {
            this.onSuccess(response.data)
            this.close()
          })
          .catch((error) => {
            this.onError(error)
            // do nothing else, template will show form errors
          })
      },
    },
  }

</script>

<style lang="stylus" scoped>

</style>
